import React, {Component} from "react";
import {connect} from "react-redux";

import Audiences from "./Audiences/Audiences";
import Creatives from "./Creatives/Creatives";

import styles from "./RightSidebar.module.scss";

import {ReactComponent as ArrowLeftIcon} from "img/arrow-left.svg";
import {ReactComponent as AudiencesIcon} from "img/app-7.svg";
import {ReactComponent as CreativesIcon} from "img/app-6.svg";

import {toggleRightSidebar} from "store/actions";

class RightSidebar extends Component {
	state = {
		opened: false,
	}

	render() {
		const {
			toggleRightSidebar,
			rightSidebarOpened,
		} = this.props;

		const {
			opened,
		} = this.state;

		return (
			<section className={`${styles.section} ${rightSidebarOpened ? "" : "_hidden"}`}>
				<button
					className={styles.arrow}
					onClick={() => toggleRightSidebar(!rightSidebarOpened)}
				>
					<ArrowLeftIcon/>
				</button>
				<Audiences opened={opened}/>
				<Creatives opened={opened}/>
				<div className={styles.buttons}>
					<button
						className={`${styles.btn} _audiences ${opened === "audiences" ? "_active" : ""}`}
						onClick={() => this.setState({opened: opened === "audiences" ? false : "audiences"})}
					>
						<AudiencesIcon/>
					</button>
					<button
						className={`${styles.btn} _creatives ${opened === "creatives" ? "_active" : ""}`}
						onClick={() => this.setState({opened: opened === "creatives" ? false : "creatives"})}
					>
						<CreativesIcon/>
					</button>
				</div>
			</section>
		)
	}
}

export default connect(
	state => ({
		rightSidebarOpened: state.rightSidebarOpened,
		sidebarTransitionEnd: state.sidebarTransitionEnd,
	}),
	dispatch => toggleRightSidebar(dispatch),
)(RightSidebar);