import image1 from "img/example/html/html-1.jpg";
import image2 from "img/example/html/html-2.jpg";
import image3 from "img/example/html/html-3.jpg";

export const testOptions = [
	{label: "Option 1", value: "Option 1"},
	{label: "Option 2", value: "Option 2"},
	{label: "Option 3", value: "Option 3"},
];

export const locationOptions = [
	{ value: 22, label: "State" },
	{ value: 23, label: "City" },
	{ value: 17, label: "Zip Code" },
	{ value: 18, label: "House District" },
	{ value: 19, label: "Congress District" },
	{ value: 20, label: "Senate District" },
	{ value: 21, label: "County" },
	{ value: 24, label: "Polling Booth" },
	{ value: 241, label: "Custom Location" },
];

export const creativesList = [
	{
		id: "001",
		checked: false,
		image: image1,
		title: "Violet Banner",
		uploaded: "25 August 2018",
		size: "250x250",
		status: "rejected",
	},
	{
		id: "002",
		checked: false,
		image: image2,
		title: "Violet Banner",
		uploaded: "25 August 2018",
		size: "250x250",
		status: "pending",
	},
	{
		id: "003",
		checked: false,
		image: image3,
		title: "Violet Banner",
		uploaded: "25 August 2018",
		size: "250x250",
		status: "approved",
	},
	{
		id: "004",
		checked: false,
		image: image1,
		title: "Violet Banner",
		uploaded: "25 August 2018",
		size: "250x250",
		status: "rejected",
	},
];

export const templateBlocks = [
	{
		title: "Campaign Info",
		checked: false,
	},
	{
		title: "Creative Group",
		checked: false,
	},
	{
		title: "Geo Fencing",
		checked: false,
	},
	{
		title: "Zip Codes",
		checked: false,
	},
	{
		title: "Location Selection",
		checked: false,
		list: [
			{
				title: "State",
				checked: false,
			},
			{
				title: "City",
				checked: false,
			},
			{
				title: "County",
				checked: false,
			},
			{
				title: "House District",
				checked: false,
			},
			{
				title: "Congress District",
				checked: false,
			},
			{
				title: "Senate State",
				checked: false,
			},
		],
	},
	{
		title: "Audience Selection",
		checked: false,
	},
	{
		title: "Demographics",
		checked: false,
		list: [
			{
				title: "Age Group",
				checked: false,
			},
			{
				title: "Gender",
				checked: false,
			},
			{
				title: "Language",
				checked: false,
			},
			{
				title: "Income Range",
				checked: false,
			},
		],
	},
	{
		title: "Technology",
		checked: false,
		list: [
			{
				title: "Device Type",
				checked: false,
			},
			{
				title: "OS",
				list: [
					{
						title: "OS Version",
						checked: true,
					},
				],
			},
			{
				title: "Manufacturer",
				list: [
					{
						title: "Devices",
						checked: false,
					},
				],
			},
			{
				title: "Carrier",
				checked: false,
			},
			{
				title: "Network Type",
				checked: false,
			},
			{
				title: "Chanel (web/app)",
				checked: false,
			},
		],
	},
	{
		title: "Inventory Selection",
		checked: false,
	},
	{
		title: "Shceduling",
		checked: false,
	},
	{
		title: "Goal Selection",
		checked: false,
		list: [
			{
				title: "Auto Optimization",
				checked: false,
			},
		],
	},
	{
		title: "Bid Strategy",
		checked: false,
		list: [
			{
				title: "Frequency Capping",
				checked: false,
			},
			{
				title: "Bid AI",
				checked: false,
			},
			{
				title: "Bid Pacing",
				checked: false,
			},
			{
				title: "Impression Capping",
				checked: false,
			},
		],
	},
];

export const audienceTypesList = [
	{title: "Custom Audience", iconName: "CustomAudience", url: "/custom"},
	{title: "Campaign Audience", iconName: "CampaignAudience", url: "/campaign"},
	{title: "Segmented Audience", iconName: "SegmentedAudience", url: "/segmented"},
	{title: "Tagged Audience", iconName: "TaggedAudience", url: "/tagged"},
	{title: "Geo-Farmed Audience", iconName: "GeofarmedAudience", url: "/geo-farmed"},
];

export const importCreativesList = [
	{
		title: "Image",
		icon: "BannerXS",
		url: "/image",
	},
	{
		title: "HTML",
		icon: "HtmlXS",
		url: "/html",
	},
	{
		title: "Audio",
		icon: "VoiceXS",
		url: "/audio",
	},
	{
		title: "GOTV",
		icon: "gotvXS",
		url: "/gotv",
	},
	{
		title: "Video",
		icon: "VideoXS",
		url: "/video",
	},
	{
		title: "Ticker",
		icon: "scrollXS",
		url: "/ticker",
	},
];