import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Icon} from "factor";

import styles from "./SelectType.module.scss";

const list = [
	{
		title: "Audience Campaign",
		text: "Running a campaign by ficusing on audience segments like ownlist, geo-framed, visiting audience, campaign audience etc.",
		icon: <Icon name="CustomAudience"/>, url: "/custom",
	},
	{
		title: "Location Campaign",
		text: "Running a campaign by ficusing on audience segments like ownlist, geo-framed, visiting audience, campaign audience etc.",
		icon: <Icon name="GeofarmedAudience"/>, url: "/campaign",
	},
	{
		title: "Advanced Campaign",
		text: "Running a campaign by ficusing on audience segments like ownlist, geo-framed, visiting audience, campaign audience etc.",
		icon: <Icon name="Advanced"/>, url: "/advanced",
	},
	{
		title: "Create Template",
		text: "Running a campaign by ficusing on audience segments like ownlist, geo-framed, visiting audience, campaign audience etc.",
		icon: <Icon name="Template"/>, url: "/template",
	},
];

export default class SelectType extends Component {
	render() {
		return (
			<div className={styles.container}>
				<div className={styles.list}>
					{list.map(i => (
						<Link to={i.url} className={`block ${styles.item}`} key={i.title}>
							<div className={styles.itemHeader}>
								<div className={styles.itemIcon}>
									{i.icon}
								</div>
								<h4 className={styles.itemTitle}>
									{i.title}
								</h4>
							</div>
							<p className={styles.itemP}>
								{i.text}
							</p>
						</Link>
					))}
				</div>
			</div>
		);
	}
}