import React, {Component} from "react";
import {connect} from "react-redux";
import * as d3 from "d3";
import {scaleQuantile} from "d3-scale";

import styles from "./Scheduling.module.scss";

class Scheduling extends Component {
	componentDidMount() {
		this.sidebarTransitionEnd = this.props.sidebarTransitionEnd;

		this.createChart();
		window.addEventListener("resize", this.createChart);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.createChart);
	}

	componentDidUpdate() {
		if(this.sidebarTransitionEnd !== this.props.sidebarTransitionEnd) {
			this.sidebarTransitionEnd = this.props.sidebarTransitionEnd;
			this.createChart();
		}
	}

	createChart = () => {
		//clear
		d3.select("#chart svg").remove();

		const marginLeft = 36;
		const chartWidth = document.querySelector("#chart").offsetWidth;
		const width = chartWidth,
			height = (12 + 11) + (24 * 12) + ((24 - 1) * 2),
			colors = ["#ffffff", "#90e2cc","#5fceb1","#3bad8e","#2e7662"],
			days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
			times = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", ],
			datasets = ["data.tsv"],
			xGrid = (((width - marginLeft) - ((7 - 1) * 4)) / 7 + 4);
		const svg = d3.select("#chart").append("svg")
			.attr("width", width)
			.attr("height", height)
			.append("g")
			.attr("transform", `translate(${marginLeft}, ${12 + 11})`);

		svg.selectAll(".dayLabel")
			.data(days)
			.enter().append("text")
			.text(function (d) { return d; })
			.attr("x", (d, i) => `${i * xGrid + xGrid / 2}`)
			.attr("y", 0)
			.style("text-anchor", "middle")
			.style("font-family", "Lato Bold")
			.style("font-size", `${10/16}rem`)
			.style("line-height", `${12/10}em`)
			.attr("transform", `translate(0, -11)`)
			.attr("class", function (d, i) { return ((i >= 0 && i <= 4) ? "dayLabel mono axis axis-workweek" : "dayLabel mono axis"); });

		svg.selectAll(".timeLabel")
			.data(times)
			.enter().append("text")
			.text(function(d) { return d; })
			.attr("x", 0)
			.attr("y", function (d, i) { return i * 14 + 10; })
			.style("text-anchor", "start")
			.style("font-family", "Lato Regular")
			.style("font-size", `${9/16}rem`)
			.style("line-height", `${11/9}em`)
			.attr("transform", `translate(-36, 0)`)
			.attr("class", function(d, i) { return ((i >= 7 && i <= 16) ? "timeLabel mono axis axis-worktime" : "timeLabel mono axis"); });

		const heatmapChart = tsvFile => {
			d3.tsv(tsvFile, d => {
				return {
					day: +d.day,
					hour: +d.hour,
					value: +d.value
				};
			}).then(data => {
				const max = d3.max(data, d => d.value);
				const colorScale = scaleQuantile()
					.domain([0, 1, max])
					.range(colors);

				const cards = svg.selectAll(".hour")
					.data(data);

				cards.enter().append("rect")
					.attr("x", d => `${xGrid * (d.day - 1)}`)
					.attr("y", d => (d.hour - 1) * (12 + 2))
					.attr("class", "hour bordered")
					.style("width", `calc(${100 / 7}% - ${((7 - 1) * 4 + marginLeft) / 7 / 16}rem)`)
					.attr("height", 12)
					.style("fill", function(d) { return colorScale(d.value); })
					.append("title").text(d => d.value);

				cards.exit().remove();
			});
		};

		heatmapChart(datasets[0]);
	}

	render() {
		const {
			className,
		} = this.props;

		return (
			<div  className={`block ${className || ""}`}>
				<div className={styles.header}>
					<div className="blockTitle">
						<h4>
							Scheduling
						</h4>
					</div>
					<div className={styles.description}>
						<h4 className={styles.descriptionTitle}>
							max bid multiplier
						</h4>
						<p className={styles.descriptionP}>
							Lower % values will result in to fewer ads running <br/>in a given time window.
						</p>
					</div>
					<div className={styles.legend}>
						{new Array(...new Array(5)).map((i, k) => (
							<div className={styles.legendItem} key={k}>
								<div
									className={styles.legendView}
									style={{
										backgroundColor: k > 0 ? `hsl(164, ${63 - (19 * (k / 4))}%, ${86 - (54 * (k / 4))}%)` : "",
									}}
								></div>
								<span className={styles.legendValue}>
									{k * 25}%
								</span>
							</div>
						))}
					</div>
				</div>
				<div id="chart"></div>
			</div>
		)
	}
}

export default connect(
	state => ({sidebarTransitionEnd: state.sidebarTransitionEnd}),
)(Scheduling);