import React from "react";
import {Checkbox, Icon} from "factor";
import {connect} from "react-redux";

import {setDialogVisibility} from "store/actions";

// import Menu from "components/Menu/Menu";

import styles from "./CreativesListItem.module.scss";

// import {moreActions, totalMoreActions} from "components/consts";

const CreativesListItem = props => {
	const {
		setDialogVisibility,
		id,
		checked,
		view,
		onCheckboxChange,
		image,
		title,
		uploaded,
		size,
		status,
		isTotal,
		creativesNumber,
		creativesStatus,
		created,
		partOf,
		type,
	} = props;

	const onTileClick = ({target}) => {
		if(view === "list" || target.closest("button")) {
			return;
		}

		onCheckboxChange();
	};

	const totalClass = isTotal ? "_total" : "";
	const viewClass = view === "tile" ? "_tile" : "";
	const checkedClass = checked ? "_active" : "";

	return (
		<div
			className={`${styles.container} ${totalClass} ${viewClass} ${checkedClass}`}
			onClick={onTileClick}
		>
			<div className={styles.checkbox}>
				<Checkbox
					label=""
					checked={checked}
					onChange={onCheckboxChange}
				/>
			</div>
			{!isTotal &&
				<div className={styles.image}>
					<img src={image} alt={title}/>
				</div>
			}
			<div className={styles.info}>
				<div className={styles.title}>
					{!isTotal &&
						<i className={styles.icon}>
							<Icon name="HtmlSM"/>
						</i>
					}
					<h4>
						{title}
					</h4>
				</div>
				{!isTotal ?
					<div className={styles.infoRow}>
						<div className={styles.uploaded}>
							<span>Uploaded on: </span>
							{uploaded}
						</div>
						<div className={styles.size}>
							<span>Size: </span>
							{size}
						</div>
						<div className={`${styles.status} _${status}`}>
							{status}
						</div>
					</div>
					:
					<div className={styles.infoRow}>
						<div className={styles.infoItem}>
							<span>
								Created on:
							</span>
							{created}
						</div>
						<div className={`${styles.infoItem} _creatives-number`}>
							<div className={styles.infoTitle}>
								{creativesNumber} Creative{creativesNumber > 1 ? "s" : ""}
							</div>
							<div className={styles.statuses}>
								{creativesStatus && Object.keys(creativesStatus).map(i => (
									<span className={`_${i}`} key={i}>
										{creativesStatus[i]}
									</span>
								))}
							</div>
						</div>
						<div className={styles.infoWrapper}>
							<div className={styles.infoItem}>
								<span>
									Type:
								</span>
								{type}
							</div>
							<div className={styles.infoItem}>
								<span>
									Part of:
								</span>
								{partOf}
							</div>
						</div>
					</div>
				}
			</div>
			{!isTotal &&
				<button className={styles.preview} onClick={() => setDialogVisibility(true)}>
					<Icon name="Impressions"/>
					Preview
				</button>
			}
			{/*<Menu*/}
				{/*className={`${styles.more} ${isTotal ? "ml-auto" : ""}`}*/}
				{/*list={!isTotal ? moreActions : totalMoreActions}*/}
				{/*isTile={(view === "tile" && !isTotal)}*/}
			{/*/>*/}
		</div>
	)
}

export default connect(
	state => ({}),
	dispatch => (setDialogVisibility(dispatch))
)(CreativesListItem);