import React, {Component} from "react";
import {Icon, Tooltip, TextField, Checkbox, ButtonCircle} from "factor";

import Sidebar from "components/SideBar/Sidebar";
import Main from "components/Main/Main";
import MainContent from "components/MainContent/MainContent";
import Textarea from "components/Textarea/Textarea";
import Segment from "components/Segment/Segment";

import styles from "./CustomTemplate.module.scss";

import {templateBlocks} from "components/consts";

class CustomTemplate extends Component {
	state = {
		templateName: "",
		templateDescription: "",
		blocks: JSON.parse(JSON.stringify(templateBlocks)),
	}

	render() {
		const {
			templateDescription,
			templateName,
			blocks,
		} = this.state;

		return (
			<Main>
				<Sidebar header={{title: "New Custom Template", icon: <Icon name="Template"/>}}>
					<div className="mb-3">
						<TextField
							label="* Name of the Template"
							value={templateName}
							onChange={(v) => this.setState({templateName: v})}
						/>
					</div>
					<div>
						<Textarea
							className={styles.textarea}
							label="* HTML Code"
							placeholder=""
							value={templateDescription}
							onChange={(v) => this.setState({templateDescription: v})}
						/>
					</div>
				</Sidebar>
				<MainContent>
					{blocks.map(i => (
						<div  className={`${styles.item}`} key={i.title}>
							<Checkbox
								className={styles.itemCheckbox}
								checked={i.checked}
								onChange={() => {
									i.checked = !i.checked;
									this.setState({blocks});
								}}
							/>
							<div  className={`block ${styles.block} ${i.checked ? "_checked" : ""}`}>
								<div className={styles.blockHeader}>
									<div className={styles.blockTitle}>
										<h4>
											{i.title}
										</h4>
										<div className={styles.blockTooltip}>
											<Tooltip label={i.title}>
												<Icon className="_size-14" name="Info"/>
											</Tooltip>
										</div>
									</div>
								</div>
								{i.list &&
									<Segment
										list={i.list}
										onChange={() => this.setState({blocks})}
									/>
								}

								{/*<div className={`row ${styles.blockRow}`}>*/}

								{/*</div>*/}
							</div>
							<ButtonCircle outline iconName="DragIndicator" className={`_size-16 ${styles.itemDragger}`}/>
						</div>
					))}

				</MainContent>
			</Main>
		)
	}
}

export default CustomTemplate;