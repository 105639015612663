import React, {Component} from "react";

import CreativesSidebar from "components/RightSidebar/Creatives/CreativesSidebar/CreativesSidebar";

import GOTVSidebar from "./GOTVSidebar/GOTVSidebar";

import styles from "./GOTV.module.scss";

export default class GOTV extends Component {
	constructor() {
		super();
	}

	render() {
		return (
			<CreativesSidebar creative={{title: "GOTV", icon: "gotvSM"}}>
				<GOTVSidebar/>
			</CreativesSidebar>
		);
	}
}