import React, {Component} from "react";
import {Icon} from "factor";

import styles from "./Segment.module.scss";

class Segment extends Component {
	state = {
		search: "",
	}

	render() {
		const {
			segment,
			itemOnClick,
			selectAll,
		} = this.props;

		return (
			<div className={styles.segment}>
				<header className={styles.header}>
					<i className={styles.image}>
						<img src={segment.img} alt=""/>
					</i>
					<h3 className={styles.title}>
						{segment.title}
					</h3>
				</header>
				<div className={styles.lists}>
					{segment.lists.map(i => {
						const allChecked = i.list.length === i.list.filter(iii => iii.checked).length;

						return (
							<div className={styles.list} key={i.title}>
								<div className={`${styles.listTitle} ${allChecked ? "_checked" : ""}`}>
									{i.title}
									<button
										className={styles.selectAll}
										onClick={() => selectAll(segment.title, i.title, !allChecked)}
									>
										{allChecked ? "Deselect all" : "Select all"}
									</button>
								</div>
								<ul className={styles.listWrapper}>
									{i.list.map(ii => (
										<li
											className={`${styles.item} ${ii.checked ? "_checked" : ""}`}
											key={ii.title}
											onClick={() => itemOnClick(segment.title, i.title, ii.title)}
										>
											{ii.title}
											{ii.checked &&
												<i className={styles.checked}>
													<Icon name="Done"/>
												</i>
											}
										</li>
									))}
								</ul>
							</div>
						);
					})}
				</div>
			</div>
		)
	}
}

export default Segment;