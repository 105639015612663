import React from "react";

import styles from "./Chip.module.scss";

const Chip = props => {
	const {
		title,
	} = props;

	return (
		<div className={styles.chip}>
			{title}
			<button className={`btn-close ${styles.chipRemove}`}></button>
		</div>
	)
};

export default Chip;