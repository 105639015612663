import React, {Component} from "react";
import {
	Select,
	ButtonsGroup,
	ButtonCircle,
} from "factor";

import Textarea from "components/Textarea/Textarea";
import File from "components/File/File";
import Player from "components/Player/Player";

import styles from "./AudioSidebar.module.scss";

import {testOptions} from "components/consts";

const daastXmlOrUploadAudionArr = [
	{title: "DAAST XML", value: "DAAST XML"},
	{title: "Upload Audio", value: "Upload Audio"},
];

class AudioSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			daastType: null,
			daastVersion: null,
			daastPlacements: null,
			daastXmlOrUploadAudion: daastXmlOrUploadAudionArr[1].value,
			daastXml: "",
			audioFileName: undefined,
		}
	}

	render() {
		const {
			daastType,
			daastVersion,
			daastPlacements,
			daastXmlOrUploadAudion,
			daastXml,
			audioFileName,
		} = this.state;

		return (
			<div className={styles.container}>
				<div className={styles.row}>
					<Select
						className={styles.select}
						label="DAAST Type"
						showControlLabel
						placeholder="Select"
						value={daastType}
						options={testOptions}
						onChange={(v) => this.setState({daastType: v})}
					/>
				</div>
				<div className={styles.row}>
					<Select
						className={styles.select}
						label="DAAST Version"
						showControlLabel
						placeholder="Select"
						value={daastVersion}
						options={testOptions}
						onChange={(v) => this.setState({daastVersion: v})}
					/>
				</div>
				<div className={styles.row}>
					<Select
						className={styles.select}
						label="DAAST Placements"
						showControlLabel
						placeholder="Select"
						value={daastPlacements}
						options={testOptions}
						onChange={(v) => this.setState({daastPlacements: v})}
					/>
				</div>
				<div className={`d-flex flex-column align-items-center ${styles.row}`}>
					<ButtonsGroup
						items={daastXmlOrUploadAudionArr}
						value={daastXmlOrUploadAudion}
						onChange={(v) => this.setState({daastXmlOrUploadAudion: v})}
					/>
					<p className={styles.comment}>
						* Add XML or Upload Audio
					</p>
				</div>
				{daastXmlOrUploadAudion === "DAAST XML" ?
					<div className={styles.row}>
						<Textarea
							className={styles.textarea}
							label="Enter your DAAST XML Code Here"
							placeholder=""
							value={daastXml}
							onChange={(v) => this.setState({daastXml: v})}
						/>
					</div>
					:
					<div className={`${styles.row}`}>
						<div className="row">
							<div className="col-6">
								<File
									label="* Upload Audio"
									btnTitle="Browse"
									onChange={({target}) => this.setState({audioFileName: target.files[0].name})}
								/>
							</div>
						</div>
						{audioFileName &&
							<div className={`${styles.block} mt-1`}>
								<Player/>
								<div className="d-flex justify-content-between align-items-center mt-3">
									<div className={styles.fileName}>
										{audioFileName}
									</div>
									<ButtonCircle outline iconName="More" className={`_size-16 ${styles.audioFileMore}`}/>
								</div>
							</div>
						}
					</div>
				}
			</div>
		);
	}
}


export default AudioSidebar;