import React, {Component} from "react";
import {connect} from "react-redux";
import {Icon} from "factor";

import styles from "./Creatives.module.scss";

import CreativesSidebar from "./CreativesSidebar/CreativesSidebar";
import Image from "./Image/Image";
import HTML from "./HTML/HTML";
import Audio from "./Audio/Audio";
import GOTV from "./GOTV/GOTV";
import Video from "./Video/Video";
import Ticker from "./Ticker/Ticker";

class Audiences extends Component {
	render() {
		const {
			opened,
			selectedCreativeType,
		} = this.props;

		return (() => {
			switch(opened) {
				case "creatives":
					return (() => {
						switch(selectedCreativeType) {
							case "Image":
								return <Image/>;
							case "HTML":
								return <HTML/>;
							case "Audio":
								return <Audio/>;
							case "GOTV":
								return <GOTV/>;
							case "Video":
								return <Video/>;
							case "Ticker":
								return <Ticker/>;
							default:
								return <CreativesSidebar/>;
						}
					})();
				default:
					return null;
			}
		})();
	}
}

export default connect(
	state => ({selectedCreativeType: state.selectedCreativeType}),
)(Audiences);