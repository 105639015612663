import React, {Component} from "react";
import {connect} from "react-redux";
import {Icon, Tooltip, DatesPicker, Select, Button, TextField, Tumbler} from "factor";

import Sidebar from "components/SideBar/Sidebar";
import Main from "components/Main/Main";
import MainContent from "components/MainContent/MainContent";
import CreativesList from "components/CreativesList/CreativesList";
import Chip from "components/Chip/Chip";
import Radio from "components/Radio/Radio";
import RightSidebar from "components/RightSidebar/RightSidebar";
import Locations from "components/Locations/Locations";
import Scheduling from "components/Scheduling/Scheduling";

import styles from "./AdvancedCampaign.module.scss";

import {testOptions} from "components/consts";

class AdvancedCampaign extends Component {
	state = {
		dateRange: {
			start: new Date(1541192400000),
			end: new Date(1541765208969),
		},
		campaignName: "",
		country: null,
		timezone: null,
		campaignGroup: null,
		deviceType: null,
		adDomain: "",

		creativeGroup: null,

		ageGroups: null,
		gender: null,
		language: null,
		interest: null,
		incomeRange: null,
		nationality: null,

		carriers: null,
		networkTypes: null,
		trafficType: null,
		manufacturer: null,
		os: null,

		matrix: null,
		goal: "",
		servingParameter: 0,

		totalBudget: "",
		budgetPacing: true,
		dailyBudget: "",
		maxBid: "",
		phoneMaxBid: "",
		tabletMaxBid: "",
		desktopMaxBid: "",
	}

	render() {
		const {
			rightSidebarOpened,
		} = this.props;

		const {
			dateRange,
			campaignName,
			country,
			timezone,
			campaignGroup,
			deviceType,
			adDomain,

			creativeGroup,

			ageGroups,
			gender,
			language,
			interest,
			incomeRange,
			nationality,

			carriers,
			networkTypes,
			trafficType,
			manufacturer,
			os,

			matrix,
			goal,
			servingParameter,

			totalBudget,
			budgetPacing,
			dailyBudget,
			maxBid,
			phoneMaxBid,
			tabletMaxBid,
			desktopMaxBid,
		} = this.state;

		return (
			<Main>
				<Sidebar header={{title: "Advanced Campaign", icon: <Icon name="Advanced"/>}}>
					<div className={styles.info}>
						<h3 className={styles.infoTitle}>
							Campaign Info
						</h3>
						<ul className={styles.infoList}>
							{[
								"Name: Trump2020",
								"Group: October Campaign",
								"Budget: $2,500.00",
								"Daily Budget: $200.00",
								"Max Bid: $2.50"
							].map(i => (
								<li key={i}>
									{i}
								</li>
							))}
						</ul>
					</div>
					<CreativesList
						withControls={false}
						view="tile"
						column={true}
					/>
				</Sidebar>
				<MainContent className={rightSidebarOpened ? "_right-sidebar-opened" : ""}>
					<RightSidebar/>
					<div  className={`block ${styles.block}`}>
						<div className={styles.blockHeader}>
							<div className={styles.blockTitle}>
								<h4>
									Campaign Info
								</h4>
								<div className={styles.blockTooltip}>
									<Tooltip label="Campaign Info">
										<Icon className="_size-14" name="Info"/>
									</Tooltip>
								</div>
							</div>
							<DatesPicker
								updateDateRange={(start, end) => this.setState({
									dateRange: {
										start: new Date(start),
										end: new Date(end),
									}
								})}
								dateFormat="DD/MM/YYYY"
								dateRange={dateRange}
								datePickerProps={{
									numberOfCalendars: 2,
									maximumDate: new Date(),
									animationAxis: 'Y',
								}}
							/>
						</div>
						<div className={`row ${styles.blockRow}`}>
							<div className="col-4">
								<TextField
									label={<React.Fragment>
										<span>
											* Campaign Name
										</span>
										{/*<Tooltip label="* Campaign Name">*/}
											{/*<Icon className="_size-14 ml-2" name="Info"/>*/}
										{/*</Tooltip>*/}
									</React.Fragment>}
									value={campaignName}
									onChange={v => this.setState({campaignName: v})}
								/>
							</div>
							<div className="col-4">
								<Select
									label="* Select Country"
									showControlLabel
									options={testOptions}
									value={country}
									onChange={v => this.setState({country: v})}
								/>
							</div>
							<div className="col-4">
								<Select
									label="* Select Timezone"
									showControlLabel
									options={testOptions}
									value={timezone}
									onChange={v => this.setState({timezone: v})}
								/>
							</div>
						</div>
						<div className={`row ${styles.blockRow}`}>
							<div className="col-4">
								<Select
									label="* Campaign Group"
									showControlLabel
									options={testOptions}
									value={campaignGroup}
									onChange={v => this.setState({campaignGroup: v})}
								/>
							</div>
							<div className="col-4">
								<Select
									label="* Device Type"
									showControlLabel
									options={testOptions}
									value={deviceType}
									onChange={v => this.setState({deviceType: v})}
								/>
							</div>
							<div className="col-4">
								<TextField
									label={<React.Fragment>
										<span>
											* Ad Domain
										</span>
										{/*<Tooltip label="* Ad Domain">*/}
											{/*<Icon className="_size-14 ml-2" name="Info"/>*/}
										{/*</Tooltip>*/}
									</React.Fragment>}
									value={adDomain}
									onChange={(v) => this.setState({adDomain: v})}
								/>
							</div>
						</div>
					</div>

					<div  className={`block ${styles.block}`}>
						<div className={styles.blockHeader}>
							<div className={styles.blockTitle}>
								<h4>
									Creative Group
								</h4>
								<div className={styles.blockTooltip}>
									<Tooltip label="Creative Group">
										<Icon className="_size-14" name="Info"/>
									</Tooltip>
								</div>
							</div>
						</div>
						<div className={`row align-items-center ${styles.blockRow}`}>
							<div className="col-7">
								<Select
									className="_left-label"
									label="* Add Creative Group"
									showControlLabel
									placeholder="Select Group..."
									options={testOptions}
									value={creativeGroup}
									onChange={v => this.setState({creativeGroup: v})}
									isSearchable
									isMulti
									allSelectable
								/>
							</div>
							<div className="col-5">
								<Button className={`btn-round _conflower-blue`}>
									<span className="btn-round__prefix">
										+
									</span>
									New Creative Group
								</Button>
							</div>
						</div>
						<div className={`${styles.blockRow}`}>
							{["March Creatives", "Rally Creatives", "April Creatives"].map(i => (
								<Chip title={i} key={i}/>
							))}
						</div>
					</div>

					{/*AUDIENCES*/}
					<div  className={`block ${styles.block}`}>
						<div className={styles.blockHeader}>
							<div className={styles.blockTitle}>
								<h4>
									Audiences
								</h4>
								<div className={styles.blockTooltip}>
									<Tooltip label="Audiences">
										<Icon className="_size-14" name="Info"/>
									</Tooltip>
								</div>
							</div>
						</div>
						<div className={`row align-items-center ${styles.blockRow}`}>
							<div className="col-7">
								<Select
									className="_left-label"
									label="* Add Audience Group"
									showControlLabel
									placeholder="Select Group..."
									options={testOptions}
									value={creativeGroup}
									onChange={v => this.setState({creativeGroup: v})}
								/>
							</div>
							<div className="col-5">
								<Button className={`btn-round _conflower-blue`}>
									<span className="btn-round__prefix">
										+
									</span>
									New Audiences
								</Button>
							</div>
						</div>
						<div className={`${styles.blockRow}`}>
							{["Audience 1", "geofarmed 1", "visited booth"].map(i => (
								<Chip title={i} key={i}/>
							))}
						</div>
					</div>

					{/*LOCATIONS*/}
					<Locations className={styles.block}/>

					{/*DEMOGRAPHICS*/}
					<div  className={`block ${styles.block}`}>
						<div className={styles.blockHeader}>
							<div className={styles.blockTitle}>
								<h4>
									Demographics
								</h4>
								<div className={styles.blockTooltip}>
									<Tooltip label="Demographics">
										<Icon className="_size-14" name="Info"/>
									</Tooltip>
								</div>
							</div>
						</div>
						<div className={`row ${styles.blockRow}`}>
							<div className="col-4">
								<Select
									label="Age Groups"
									showControlLabel
									options={testOptions}
									value={ageGroups}
									onChange={v => this.setState({ageGroups: v})}
								/>
							</div>
							<div className="col-4">
								<Select
									label="Gender"
									showControlLabel
									options={testOptions}
									value={gender}
									onChange={v => this.setState({gender: v})}
								/>
							</div>
							<div className="col-4">
								<Select
									label="Language"
									showControlLabel
									options={testOptions}
									value={language}
									onChange={v => this.setState({language: v})}
								/>
							</div>
						</div>
						<div className={`row ${styles.blockRow}`}>
							<div className="col-4">
								<Select
									label="Interest"
									showControlLabel
									options={testOptions}
									value={interest}
									onChange={v => this.setState({interest: v})}
								/>
							</div>
							<div className="col-4">
								<Select
									label="Income Range"
									showControlLabel
									options={testOptions}
									value={incomeRange}
									onChange={v => this.setState({incomeRange: v})}
								/>
							</div>
							<div className="col-4">
								<Select
									label="Nationality"
									showControlLabel
									options={testOptions}
									value={nationality}
									onChange={v => this.setState({nationality: v})}
								/>
							</div>
						</div>
					</div>

					{/*TECHNOLOGY*/}
					<div  className={`block ${styles.block}`}>
						<div className={styles.blockHeader}>
							<div className={styles.blockTitle}>
								<h4>
									Technology
								</h4>
								<div className={styles.blockTooltip}>
									<Tooltip label="Technology">
										<Icon className="_size-14" name="Info"/>
									</Tooltip>
								</div>
							</div>
						</div>
						<div className={`row ${styles.blockRow}`}>
							<div className="col-4">
								<Select
									label="Carriers"
									showControlLabel
									options={testOptions}
									value={carriers}
									onChange={v => this.setState({carriers: v})}
								/>
							</div>
							<div className="col-4">
								<Select
									label="Network Types"
									showControlLabel
									options={testOptions}
									value={networkTypes}
									onChange={v => this.setState({networkTypes: v})}
								/>
							</div>
							<div className="col-4">
								<Select
									label="Traffic Type"
									showControlLabel
									options={testOptions}
									value={trafficType}
									onChange={v => this.setState({trafficType: v})}
								/>
							</div>
						</div>
						<div className={`row ${styles.blockRow}`}>
							<div className="col-4">
								<Select
									label="Manufacturer"
									showControlLabel
									options={testOptions}
									value={manufacturer}
									onChange={v => this.setState({manufacturer: v})}
								/>
							</div>
							<div className="col-4">
								<Select
									label="OS"
									showControlLabel
									options={testOptions}
									value={os}
									onChange={v => this.setState({os: v})}
								/>
							</div>
						</div>
					</div>

					{/*INVENTORY*/}
					<div  className={`block ${styles.block}`}>
						<div className={styles.blockHeader}>
							<div className={styles.blockTitle}>
								<h4>
									Inventory
								</h4>
								<div className={styles.blockTooltip}>
									<Tooltip label="Inventory">
										<Icon className="_size-14" name="Info"/>
									</Tooltip>
								</div>
							</div>
						</div>
						<div className={`row align-items-center ${styles.blockRow}`}>
							<div className="col-7">
								<Select
									className="_left-label"
									label="* Add Inventory Group"
									showControlLabel
									placeholder="Select Group..."
									options={testOptions}
									value={creativeGroup}
									onChange={v => this.setState({creativeGroup: v})}
								/>
							</div>
							<div className="col-5">
								<Button className={`btn-round _conflower-blue`}>
									<span className="btn-round__prefix">
										+
									</span>
									New Group
								</Button>
							</div>
						</div>
						<div className={`${styles.blockRow}`}>
							{["Publishers 1", "Publishers 2", "Publishers 3"].map(i => (
								<Chip title={i} key={i}/>
							))}
						</div>
					</div>

					{/*SCHEDULING*/}
					<Scheduling className={styles.block}/>

					{/*GOAL*/}
					<div  className={`block ${styles.block}`}>
						<div className={styles.blockHeader}>
							<div className={styles.blockTitle}>
								<h4>
									Goal AI
								</h4>
								<div className={styles.blockTooltip}>
									<Tooltip label="Goal AI">
										<Icon className="_size-14" name="Info"/>
									</Tooltip>
								</div>
							</div>
						</div>
						<div className={`row ${styles.blockRow}`}>
							<div className="col-3">
								<Select
									label="* Choose Matrix"
									showControlLabel
									options={testOptions}
									value={matrix}
									onChange={v => this.setState({matrix: v})}
								/>
							</div>
							<div className="col-2">
								<div className="row">
									<div className="col-10">
										<TextField
											label="Set Goal"
											value={goal}
											onChange={({target}) => this.setState({goal: target.value})}
										/>
									</div>
								</div>
							</div>
							<div className={`col-7 ${styles.goalCol}`}>
								<div className="row justify-content-center">
									<div className="col-11">
										<h4 className="text-label mb-3">
											What serving parameter should be the emphasis of this campaign?
										</h4>
										<div className="row">
											{["Creatives", "Publishers", "Audience"].map((i, k) => (
												<div className="col-4" key={k}>
													<Radio
														className={styles.blockRadio}
														name="servingParameter"
														id={i}
														title={i}
														checked={k === servingParameter}
														onChange={() => this.setState({servingParameter: k})}
													/>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/*CAMPAIGN BUDGET*/}
					<div  className={`block ${styles.block}`}>
						<div className={styles.blockHeader}>
							<div className={styles.blockTitle}>
								<h4>
									Campaign Budget
								</h4>
							</div>
						</div>
						<div className={`row ${styles.blockRow}`}>
							<div className="col-3">
								<TextField
									label={<React.Fragment>
										<span>
											* Total Budget
										</span>
										{/*<Tooltip label="* Total Budget">*/}
											{/*<Icon className="_size-14 ml-2" name="Info"/>*/}
										{/*</Tooltip>*/}
									</React.Fragment>}
									value={totalBudget}
									onChange={(v) => this.setState({totalBudget: v})}
								/>
							</div>
							<div className="col-3">
								<label className="text-label">
									<span>
										Budget Pacing
									</span>
									<Tooltip label="Budget Pacing">
										<Icon className="_size-14 ml-2" name="Info"/>
									</Tooltip>
								</label>
								<Tumbler
									onOff={false}
									name="budgetPacing"
									id="budgetPacing"
									className=""
									on={budgetPacing}
									onChange={(value) => this.setState({
										budgetPacing: !budgetPacing,
									})}
								/>
							</div>
							<div className="col-3">
								<TextField
									label={<React.Fragment>
										<span>
											* Daily Budget
										</span>
										{/*<Tooltip label="* Daily Budget">*/}
											{/*<Icon className="_size-14 ml-2" name="Info"/>*/}
										{/*</Tooltip>*/}
									</React.Fragment>}
									value={dailyBudget}
									onChange={(v) => this.setState({dailyBudget: v})}
								/>
							</div>
							<div className="col-3">
								<TextField
									label={<React.Fragment>
										<span>
											* Max Bid
										</span>
										{/*<Tooltip label="* Max Bid">*/}
											{/*<Icon className="_size-14 ml-2" name="Info"/>*/}
										{/*</Tooltip>*/}
									</React.Fragment>}
									value={maxBid}
									onChange={(v) => this.setState({maxBid: v})}
								/>
							</div>
						</div>
						<h4 className={styles.blockTitle2}>
							Device Types
						</h4>
						<div className={styles.deviceTypeRow}>
							{[
								{device: "Phone", input: phoneMaxBid, inputName: "phoneMaxBid"},
								{device: "Tablet", input: tabletMaxBid, inputName: "tabletMaxBid"},
								{device: "Desktop", input: desktopMaxBid, inputName: "desktopMaxBid"},
							].map(i => (
								<div className={styles.deviceTypeItem} key={i.device}>
									<div className={styles.deviceType}>
										<div className="d-flex align-items-center mb-3">
											<i className={styles.deviceTypeIcon}>
												<Icon name={i.device}/>
											</i>
											<h6 className={styles.deviceTypeName}>
												{i.device}
											</h6>
										</div>
										<TextField
											label={<React.Fragment>
											<span>
												* Max Bid
											</span>
												{/*<Tooltip label="* Max Bid">*/}
													{/*<Icon className="_size-14 ml-2" name="Info"/>*/}
												{/*</Tooltip>*/}
											</React.Fragment>}
											value={i.input}
											onChange={(v) => this.setState({[i.inputName]: v})}
										/>
									</div>
								</div>
							))}
						</div>
					</div>
				</MainContent>
			</Main>
		)
	}
}

export default connect(
	state => ({rightSidebarOpened: state.rightSidebarOpened}),
)(AdvancedCampaign);