import React from "react";
import {BrowserRouter} from "react-router-dom";

import Routing from "components/Routing/Routing";
import Header from "components/Header/Header";

function App() {

	return (
		<BrowserRouter>
			<div className="page">
				<Header/>
				<Routing/>
			</div>
		</BrowserRouter>
	);
}

export default App;
