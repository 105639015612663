import React, {Component} from "react";
import {
	NavigationBar,
	Dialog,
	DialogContent,
	TextField,
	Button,
} from "factor";

import HeaderContent from "./HeaderContent/HeaderContent";
import {ReactComponent as Logo} from "img/logo.svg";

import "./Header.scss";

class Header extends Component {
	constructor() {
		super();
		this.navigationBar = React.createRef();
		this.state = {
			showNewGroupDialog: false,
			newGroupName: "",
			showDeleteGroupDialog: false,
		}
	}

	toggleModal = (modal, show) => {
		this.setState({[modal]: show});
	}

	render() {
		const {
			showNewGroupDialog,
			newGroupName,
			showDeleteGroupDialog,
		} = this.state;

		const popupContent = <span>popup content</span>;

		return (
			<NavigationBar
				backButton={false}
				ref={this.navigationBar}
				leftLogo={<div className="nav-bar__circle-icon" key="logo"><Logo/></div>}
				title="Campaigns"
				popupContent={popupContent}
			>
				<React.Fragment>
					<HeaderContent/>
				</React.Fragment>
			</NavigationBar>
		)
	}
}

export default Header;