import React, {Component} from "react";
import {DatesPicker, Select, Button, ButtonCircle, Icon} from "factor";

import AudiencesSidebar from "components/RightSidebar/Audiences/AudiencesSidebar/AudiencesSidebar";

import styles from "./TaggedAudience.module.scss";

import {testOptions} from "components/consts";

class TaggedAudience extends Component {
	state = {
		dateRange: {
			start: new Date(1541192400000),
			end: new Date(1541765208969),
		},
		lastFor: null,
		cards: [
			{checked: true},
			{checked: false},
			{checked: false},
		],
		activeCardActionsIndex: undefined,
	}

	componentDidMount() {
		window.addEventListener("click", this.setCardActionsVisibility);
	}

	componentWillUnmount() {
		window.removeEventListener("click", this.setCardActionsVisibility);

	}

	setCardActionsVisibility = (e) => {
		const {
			activeCardActionsIndex,
		} = this.state;

		if(activeCardActionsIndex === undefined || e.target.closest(".js-card-actions") || e.target.closest(".js-card-more")) {
			return;
		}

		this.setState({activeCardActionsIndex: undefined});
	}

	render() {
		const {
			dateRange,
			lastFor,
			cards,
			activeCardActionsIndex,
		} = this.state;

		return (
			<AudiencesSidebar header={{title: "Tagged Audience", icon: <Icon name="TaggedAudience"/>}}>
				<div className={styles.row}>
					<div className="text-label">
						Timeframe
					</div>
					<div className={styles.datespicker}>
						<DatesPicker
							updateDateRange={(start, end) => this.setState({
								dateRange: {
									start: new Date(start),
									end: new Date(end),
								}
							})}
							dateFormat="DD/MM/YYYY"
							dateRange={dateRange}
							datePickerProps={{
								numberOfCalendars: 2,
								maximumDate: new Date(),
								animationAxis: 'Y',
							}}
						/>
					</div>
				</div>
				<div className={`row align-items-end ${styles.row}`}>
					<div className="col-6">
						<Select
							label="Last for"
							showControlLabel
							options={testOptions}
							value={lastFor}
							onChange={v => this.setState({lastFor: v})}
						/>
					</div>
					<div className="col-6">
						<Button className="btn-round _conflower-blue mb-1">
							<span className="btn-round__prefix">+</span>
							Create
						</Button>
					</div>
				</div>
				<ul className={styles.list}>
					{cards.map((i, k) => (
						<li className={styles.item} key={k}>
							<div
								className={`block ${styles.card} ${i.checked ? "_checked" : ""}`}
								onClick={({target}) => {
									if(!target.closest(`.${styles.cardMore}`) && !target.closest(`.${styles.actions}`)) {
										cards[k].checked = !cards[k].checked;
										this.setState({cards});
									}
								}}
							>
								<ButtonCircle
									outline iconName="More"
									className={`_size-16 ${styles.cardMore} js-card-more`}
									onClick={() => this.setState({activeCardActionsIndex: k})}
								/>
								<h4 className={styles.cardTitle}>
									IQM Website
								</h4>
								<ul className={styles.cardParams}>
									{["Last for 12 months", "Status: Integrated", "Visitors: 2,800"].map(ii => (
										<li className={styles.cardParam}>
											{ii}
										</li>
									))}
								</ul>
								{i.checked &&
								<i className={styles.cardIcon}>
									<Icon name="Done"/>
								</i>
								}
								{activeCardActionsIndex === k &&
								<div className={`${styles.actions} js-card-actions`}>
									<button
										className={`btn-close ${styles.actionsClose}`}
										onClick={() => this.setState({activeCardActionsIndex: undefined})}
									></button>
									<ButtonCircle outline iconName="Copy" className={`_size-16 ${styles.actionsBtn}`}/>
									<ButtonCircle outline iconName="Delete" className={`_size-16 ${styles.actionsBtn}`}/>
								</div>
								}
							</div>
						</li>
					))}
				</ul>
			</AudiencesSidebar>
		)
	}
}

export default TaggedAudience;