import React from "react";

import Sidebar from "components/SideBar/Sidebar";
import Main from "components/Main/Main";
import MainContent from "components/MainContent/MainContent";
import SelectType from "components/SelectType/SelectType";

import styles from "./Mainpage.module.scss";

const Mainpage = () => (
	<Main>
		<Sidebar mainpage>
			<SelectType/>
		</Sidebar>
		<MainContent>
			<div className={styles.empty}>
				Initiate the campaign creation process<br/>
				by choosing the type of campaign on the left.
			</div>
		</MainContent>
	</Main>
);

export default Mainpage;