import React, {Component} from "react";
import {Icon} from "factor";

import AudiencesSidebar from "components/RightSidebar/Audiences/AudiencesSidebar/AudiencesSidebar";
import Segment from "./Segment/Segment";

import styles from "./SegmentedAudience.module.scss";

import segment1 from "img/example/segments/example_logo_1.png";
import segment2 from "img/example/segments/example_logo_2.png";

class Segmented extends Component {
	state = {
		segments: [
			{
				img: segment1,
				title: "Custom Audience Segment",
				lists: [
					{
						title: "New York City",
						list: [
							{title: "Autimobile Repair ($0,50)", checked: true},
							{title: "High-end Automobile OW ($0,50)", checked: false},
						],
					},
				],
			},
			{
				img: segment2,
				title: "IQM DMP",
				lists: [
					{
						title: "Automobile Manufactures",
						list: [
							{title: "Volkswagen ($0,70)", checked: true},
							{title: "Mercedes Benz ($0,25)", checked: true},
							{title: "BMW ($0,50)", checked: false},
						],
					},
				],
			},
			{
				img: segment1,
				title: "Lotame",
				lists: [
					{
						title: "Washington DC",
						list: [
							{title: "High Net-worth Individual ($0,40)", checked: true},
							{title: "Expensive Automobile OW ($0,25)", checked: true},
							{title: "BMW ($0,50)", checked: false},
						]
					},
					{
						title: "Automobile Accessories",
						list: [
							{title: "Seat Cover Manufacturer ($0,30)", checked: true},
						]
					},
				],
			},
		],
	}

	render() {
		const {
			segments,
		} = this.state;

		return (
			<AudiencesSidebar
				header={{title: "Segmented Audience", icon: <Icon name="SegmentedAudience"/>}}
				withSearch={true}
			>
				<ul className={styles.list}>
					{segments.map(i => (
						<li className={styles.item}>
							<Segment
								key={i.title}
								segment={i}
								itemOnClick={(segmentTitle, listsTitle, itemTitle) => {
									const item = segments
										.find(i => i.title === segmentTitle)
										.lists.find(ii => ii.title === listsTitle)
										.list.find(iii => iii.title === itemTitle);
									item.checked = !item.checked;
									this.setState({segments});
								}}
								selectAll={(segmentTitle, listsTitle, checked) => {
									const list = segments
										.find(i => i.title === segmentTitle)
										.lists.find(ii => ii.title === listsTitle)
										.list;
									list.forEach((iii) => {iii.checked = checked});
									this.setState({segments});
								}}
							/>
						</li>
					))}
				</ul>
			</AudiencesSidebar>
		)
	}
}

export default Segmented;