import React, {Component} from "react";
import {Icon, Tooltip, Select, TextFieldWithIcon} from "factor";

import File from "components/File/File";
import Chip from "components/Chip/Chip";

import styles from "./Locations.module.scss";

import {ReactComponent as ArrowsIcon} from "img/arrows.svg";

import {testOptions} from "components/consts";

class Locations extends Component {
	state = {
		location: "",
		locationCountry: null,
		locationState: null,
		locationCounty: null,
	}

	render() {
		const {
			className,
		} = this.props;

		const {
			location,
			locationCountry,
			locationState,
			locationCounty,
		} = this.state;

		return (
			<div  className={`block ${className || ""}`}>
				<div className="blockHeader">
					<div className="blockTitle">
						<h4>
							Locations
						</h4>
						<div className="blockTooltip">
							<Tooltip label="Locations">
								<Icon className="_size-14" name="Info"/>
							</Tooltip>
						</div>
					</div>
					<File btnTitle="Upload CSV" btnType="btn-round"/>
				</div>
				<div className="row align-items-start blockRow">
					<div className="col-12">
						<TextFieldWithIcon
							placeholder="Browse"
							iconName="GeofarmedAudience"
							value={location}
							onChange={({target}) => this.setState({location: target.value})}
						/>

						<div className="row align-items-center blockRow">
							<div className={`col-8 ${styles.locationSelects}`}>
								<Select
									placeholder="Country"
									showControlLabel
									options={testOptions}
									value={locationCountry}
									onChange={v => this.setState({locationCountry: v})}
								/>
								<i>
									<ArrowsIcon/>
								</i>
								<Select
									placeholder="State"
									showControlLabel
									options={testOptions}
									value={locationState}
									onChange={v => this.setState({locationState: v})}
								/>
								<i>
									<ArrowsIcon/>
								</i>
								<span>
									None
								</span>
							</div>
							<div className="col-4">
								<Select
									placeholder="County"
									showControlLabel
									options={testOptions}
									value={locationCounty}
									onChange={v => this.setState({locationCounty: v})}
								/>
							</div>
						</div>
						<div className={styles.locationMap}>
							<iframe
								frameBorder="0"
								style={{border:0}}
								src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB-hR-cY7nDR7uj43o-UW6Q9ECX0CUugOc&q=USA"
								allowFullScreen
							/>
						</div>
					</div>
				</div>
				{locationCountry ?
					<div className="d-flex flex-wrap">
						{["USCON - D1", "State - AL", "Zip - 10019", "County - San Mateo"].map(i => (
							<Chip title={i} key={i}/>
						))}
					</div>
					:
					<p className={styles.p}>
						no locations selected
					</p>
				}
			</div>
		)
	}
}

export default Locations;