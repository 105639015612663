import React, {Component} from "react";
import {TextField, Button, TextFieldWithIcon} from "factor";
import {connect} from "react-redux";

import SelectType from "./SelectType/SelectType";

import styles from "./AudiencesSidebar.module.scss";

import {setActiveAudienceType} from "store/actions";

class Sidebar extends Component {
	constructor() {
		super();
		this.state = {
			audienceName: "",
			search: "",
		}
	}

	renderHeader = () => {
		const {
			header,
			withSearch,
			setActiveAudienceType,
		} = this.props;

		const {
			search,
		} = this.state;

		return (
			<header className={styles.header}>
				<div className={styles.headerWrapper}>
					{header &&
						<i className={styles.icon}>
							{header.icon}
						</i>
					}
					<h3 className={styles.title}>
						{header ? header.title : "Select a Type of Audience"}
					</h3>
					{header &&
						<button className="btn-close" onClick={() => setActiveAudienceType(undefined)}></button>
					}
				</div>
				{withSearch &&
					<div className={styles.headerSearch}>
						<TextFieldWithIcon
							iconName="Search"
							placeholder="Search"
							value={search}
							onChange={({target}) => this.setState({search: target.value})}
						/>
					</div>
				}
			</header>
		);
	}

	renderContent = () => {
		const {
			children,
		} = this.props;

		const {
			audienceName,
		} = this.state;

		if(children) {
			return (
				<div className={styles.content}>
					<div className={styles.row}>
						<TextField
							label="Audience Name"
							value={audienceName}
							onChange={(v) => this.setState({audienceName: v})}
						/>
					</div>
					{children}
					<footer className={styles.footer}>
						<Button className={`btn-square _conflower-blue ${styles.footerBtn}`}>
							Cancel
						</Button>
						<Button className={`btn-square _filled _conflower-blue ${styles.footerBtn}`}>
							Add
						</Button>
					</footer>
				</div>
			);
		} else {
			return <SelectType/>
		}
	}

	render() {
		return (
			<div className={`${styles.sidebar}`}>
				<div className={styles.container}>
					{this.renderHeader()}
					<div className={styles.main}>
						{this.renderContent()}
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
	state => ({}),
	dispatch => setActiveAudienceType(dispatch),
)(Sidebar);