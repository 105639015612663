import React from "react";
import {Switch, Route} from "react-router-dom";

import Mainpage from "components/Mainpage/Mainpage";
import AdvancedCampaign from "pages/AdvancedCampaign/AdvancedCampaign";
import CustomTemplate from "pages/CustomTemplate/CustomTemplate";

function Routing() {
	return (
		<Switch>
			<Route exact path="/" component={Mainpage}/>
			<Route exact path="/advanced" component={AdvancedCampaign}/>
			<Route exact path="/template" component={CustomTemplate}/>
		</Switch>
	);
}

export default Routing;
