import React, {Component} from "react";
import {connect} from "react-redux";
import {Icon} from "factor";

import styles from "./SelectType.module.scss";

import {setActiveAudienceType} from "store/actions";

import {audienceTypesList} from "components/consts";

audienceTypesList.forEach(i => i.icon = <Icon name={i.iconName}/>)

class SelectType extends Component {
	render() {
		const {
			setActiveAudienceType,
		} = this.props;

		return (
			<div className={styles.container}>
				<div className={styles.list}>
					{audienceTypesList.map(i => (
						<button
							className={styles.item}
							key={i.title}
							onClick={() => setActiveAudienceType(i.title)}
						>
							<div className={`${styles.itemIcon} ${i.title === "Geo-Farmed Audience" ? "_fixed-height" : ""}`}>
								{i.icon}
							</div>
							<h4 className={styles.itemTitle}>
								{i.title}
							</h4>
						</button>
					))}
				</div>
			</div>
		);
	}
}

export default connect(
	state => ({
		selectedAudienceType: state.selectedAudienceType,
	}),
	dispatch => setActiveAudienceType(dispatch),
)(SelectType);