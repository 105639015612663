import React, {Component} from "react";
import {Icon} from "factor";

import AudiencesSidebar from "components/RightSidebar/Audiences/AudiencesSidebar/AudiencesSidebar";
import File from "components/File/File";

import styles from "./CustomAudience.module.scss";

class CustomAudience extends Component {
	render() {
		return (
			<AudiencesSidebar header={{title: "Custom Audience", icon: <Icon name="CustomAudience"/>}}>
				<div className={`row ${styles.row}`}>
					<div className="col-6">
						<File label="* Select List"/>
					</div>
					<div className="col-6">
						<div className={styles.loading}>
							<div className={styles.loadingFileName}>
								MyList.csv
							</div>
							<div className={styles.loadingProgress}>
								<div className={styles.loadingFill} style={{width: `${94/215*100}%`}}></div>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.files}>
					{["Trump2020.csv", "AmericaGreat.csv", "NYC Follower.csv"].map(i => (
						<div className={styles.file}>
							{i}
							<button className={`btn-close ${styles.fileRemove}`}></button>
						</div>
					))}
				</div>
			</AudiencesSidebar>
		)
	}
}

export default CustomAudience;