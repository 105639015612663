import React from "react";

import styles from "./Radio.module.scss";

const Radio = props => {
	const {
		className,
		name,
		id,
		checked,
		onChange,
		title,
	} = props;

	return (
		<div className={`${styles.radio} ${className || ""}`}>
			<input
				type="radio"
				name={name} id={id}
				style={{display: "none"}}
				checked={checked}
				onChange={onChange}
			/>
			<label htmlFor={id}>
				{title || id}
			</label>
		</div>
	)
};

export default Radio;