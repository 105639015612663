let initialState = {
	activeSidebar: true,
	rightSidebarOpened: true,
};

function reducer(state = initialState, action) {
	switch(action.type) {
		case "AUDIENCE_TYPE_SELECT":
			return {
				...state,
				selectedAudienceType: action.payload,
			};
		case "CREATIVE_TYPE_SELECT":
			return {
				...state,
				selectedCreativeType: action.payload,
			};
		case "SIDEBAR_TRANSITION_END":
			return {
				...state,
				sidebarTransitionEnd: action.payload,
			};
		case "RIGHT_SIDEBAR_TOGGLE":
			return {
				...state,
				rightSidebarOpened: action.payload,
			};
		//OLD:
		case "DIALOG_TOGGLE":
			return {
				...state,
				dialogOpened: action.payload,
			};
		case "MODE_CHANGE":
			return {
				...state,
				mode: action.payload,
			};
		case "GET_DATA":
			return {
				...state,
				data: action.payload
			};
		case "SIDEBAR_TOGGLE":
			return {
				...state,
				activeSidebar: action.payload,
			};
		default:
			return state;
	}
}

export default reducer;