import React, {Component} from "react";

import CreativesSidebar from "components/RightSidebar/Creatives/CreativesSidebar/CreativesSidebar";
import AudioSidebar from "./AudioSidebar/AudioSidebar";

import styles from "./Audio.module.scss";

export default class Audio extends Component {
	render() {
		return (
			<CreativesSidebar creative={{title: "Audio", icon: "VoiceSM"}}>
				<AudioSidebar/>
			</CreativesSidebar>
		);
	}
}