import React, {Component} from "react";
import {Link} from "react-router-dom";
import {TextField, Button, TextFieldWithIcon} from "factor";
import {connect} from "react-redux";

import SelectType from "components/SelectType/SelectType";

import styles from "./Sidebar.module.scss";

import {ReactComponent as ArrowLeftIcon} from "img/arrow-left.svg";

import {toggleSidebar} from "store/actions";

class Sidebar extends Component {
	constructor() {
		super();
		this.state = {
			audienceName: "",
			search: "",
		}
	}

	componentDidMount() {
		window.addEventListener("resize", this.toggleSidebarOnResize);
		this.toggleSidebarOnResize();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.toggleSidebarOnResize);
	}

	toggleSidebarOnResize = () => {
		if (this.windowWidth === undefined) {
			this.windowWidth = window.innerWidth;

			if (this.windowWidth < 1280) {
				this.toggleSidebar();
			} else {
				return;
			}
		} else {
			if (this.windowWidth < 1280) {
				this.windowWidth = window.innerWidth;
				return;
			} else {
				if (window.innerWidth < 1280) {
					this.windowWidth = window.innerWidth;
					this.toggleSidebar();
				}
			}
		}
	}

	toggleSidebar = (show) => {
		const {
			toggleSidebar,
			activeSidebar,
		} = this.props;

		toggleSidebar(!activeSidebar);
	}

	renderHeader = () => {
		const {
			header,
			withSearch,
		} = this.props;

		const {
			search,
		} = this.state;

		const typeIcon = header && header.icon;

		return (
			<header className={styles.header}>
				<div className={styles.headerWrapper}>
					{header &&
						<i className={styles.icon}>
							{typeIcon}
						</i>
					}
					<h3 className={styles.title}>
						{header ? header.title : "Select Campaign Type"}
					</h3>
					{header &&
						<Link to="/" className="btn-close"/>
					}
				</div>
				{withSearch &&
					<div className={styles.headerSearch}>
						<TextFieldWithIcon
							iconName="Search"
							placeholder="Search"
							value={search}
							onChange={({target}) => this.setState({search: target.value})}
						/>
					</div>
				}
			</header>
		);
	}

	renderContent = () => {
		const {
			children,
		} = this.props;

		const {
			audienceName,
		} = this.state;

		if(children) {
			return (
				<div className={styles.content}>
					<div className={styles.row}>
						<TextField
							label="Audience Name"
							value={audienceName}
							onChange={(v) => this.setState({audienceName: v})}
						/>
					</div>
					{children}
					<footer className={styles.footer}>
						<Button className={`btn-square _conflower-blue ${styles.footerBtn}`}>
							Cancel
						</Button>
						<Button className={`btn-square _filled _conflower-blue ${styles.footerBtn}`}>
							Add
						</Button>
					</footer>
				</div>
			);
		} else {
			return <SelectType/>
		}
	}

	render() {
		const {
			toggleSidebar,
			activeSidebar,
			mainpage,
			children,
		} = this.props;

		return (
			<div className={`${styles.sidebar} ${mainpage ? "_mainpage" : ""} ${activeSidebar ? "" : "_hidden"}`}>
				<button
					className={styles.arrow}
					onClick={() => toggleSidebar(!activeSidebar)}
				>
					<ArrowLeftIcon/>
				</button>
				<div className={styles.container}>
					{this.renderHeader()}
					<div className={styles.main}>
						<div className={styles.content}>
							{children}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
	state => ({
		activeSidebar: state.activeSidebar,
		sidebarTransitionEnd: state.sidebarTransitionEnd,
	}),
	dispatch => toggleSidebar(dispatch),
)(Sidebar);