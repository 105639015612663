export const setActiveAudienceType = (dispatch) => ({
	setActiveAudienceType: (selectedAudienceType) => {
		dispatch({type: "AUDIENCE_TYPE_SELECT", payload: selectedAudienceType});
	}
});

export const setActiveCreativeType = (dispatch) => ({
	setActiveCreativeType: (selectedCreativeType) => {
		dispatch({type: "CREATIVE_TYPE_SELECT", payload: selectedCreativeType});
	}
});

export const announceMainTransitionEnd = (dispatch) => ({
	announceMainTransitionEnd: (show) => {
		dispatch({type: "SIDEBAR_TRANSITION_END", payload: show})
	}
});

export const toggleRightSidebar = (dispatch) => ({
	toggleRightSidebar: (show) => {
		dispatch({type: "RIGHT_SIDEBAR_TOGGLE", payload: show})
	}
});

//OLD
export const setDialogVisibility = (dispatch) => ({
	setDialogVisibility: (open) => {
		dispatch({type: "DIALOG_TOGGLE", payload: open});
	}
});

export const changeMode = (dispatch) => ({
	changeMode: (mode) => {
		dispatch({type: "MODE_CHANGE", payload: mode});
	}
});

export const getData = (dispatch) => ({
	getData: (data) => {
		dispatch({type: "GET_DATA", payload: data})
	}
});

export const toggleSidebar = (dispatch) => ({
	toggleSidebar: (show) => {
		dispatch({type: "SIDEBAR_TOGGLE", payload: show})
	}
});