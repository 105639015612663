import React, {Component} from "react";

import CreativesSidebar from "components/RightSidebar/Creatives/CreativesSidebar/CreativesSidebar";
import ImageSidebar from "./ImageSidebar/ImageSidebar";

import styles from "./Image.module.scss";

export default class Image extends Component {
	render() {
		return (
			<CreativesSidebar creative={{title: "Image", icon: "BannerSM"}}>
				<ImageSidebar/>
			</CreativesSidebar>
		);
	}
}