import React from "react";

import styles from "./MainContent.module.scss";

const MainContent = (props) => {
	const {
		className,
		children,
	} = props;

	return (
		<section className={`${styles.container} ${className || ""}`}>
			{children}
		</section>
	);
};

export default MainContent;