import React, {Component} from "react";
import {connect} from "react-redux";
import {Icon} from "factor";

import styles from "./Audiences.module.scss";

import AudiencesSidebar from "./AudiencesSidebar/AudiencesSidebar";
import CustomAudience from "./CustomAudience/CustomAudience";
import GeofarmedAudience from "./GeofarmedAudience/GeofarmedAudience";
import TaggedAudience from "./TaggedAudience/TaggedAudience";
import CampaignAudience from "./CampaignAudience/CampaignAudience";
import SegmentedAudience from "./SegmentedAudience/SegmentedAudience";

class Audiences extends Component {
	render() {
		const {
			opened,
			selectedAudienceType,
		} = this.props;

		return (() => {
			switch(opened) {
				case "audiences":
					return (() => {
						switch(selectedAudienceType) {
							case "Custom Audience":
								return <CustomAudience/>;
							case "Geo-Farmed Audience":
								return <GeofarmedAudience/>;
							case "Tagged Audience":
								return <TaggedAudience/>;
							case "Campaign Audience":
								return <CampaignAudience/>;
							case "Segmented Audience":
								return <SegmentedAudience/>;
							default:
								return <AudiencesSidebar/>;
						}
					})();
				default:
					return null;
			}
		})();
	}
}

export default connect(
	state => ({selectedAudienceType: state.selectedAudienceType}),
)(Audiences);