import React, {Component} from "react";
import {connect} from "react-redux";
import {Icon, Button} from "factor";

import SidebarHeader from "./SidebarHeader/SidebarHeader";
import ImportCreatives from "./ImportCreatives/ImportCreatives";

import styles from "./CreativesSidebar.module.scss";

import {setActiveCreativeType} from "store/actions";

class CreativesSidebar extends Component {

	renderHeader = () => {
		const {
			creative,
			header,
			setActiveCreativeType,
		} = this.props;

		return creative ?
			<div className={styles.creative}>
				<i className={styles.creativeIcon}>
					<Icon name={creative.icon}/>
				</i>
				<span>
					{creative.title}
				</span>
				<button className={`btn-close ${styles.creativeClose}`} onClick={() => setActiveCreativeType(undefined)}></button>
			</div>
			:
			<header className={styles.header}>
				<h3 className={styles.title}>
					Import Creatives
					{" "}
					<span>
						(Drag and Drop Creatives)
					</span>
				</h3>
				{header}
			</header>
	}

	renderContent = () => {
		const {
			children,
			creative,
		} = this.props;

		const withPreview = creative && ["Audio", "Image"].indexOf(creative.title) === -1;

		if(creative) {
			return (
				<div className={styles.content}>
					<SidebarHeader withPreview={withPreview}/>
					{children}
					<div className="d-flex justify-content-end">
						<Button className="btn-square _conflower-blue mr-3">
							Cancel
						</Button>
						<Button className="btn-square _filled _conflower-blue">
							Add
						</Button>
					</div>
				</div>
			)
		} else {
			return <ImportCreatives/>;
		}
	}

	render() {
		return (
			<div className={styles.sidebar}>
				<div className={styles.container}>
					{this.renderHeader()}
					<div className={styles.main}>
						{this.renderContent()}
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
	state => ({}),
	dispatch => setActiveCreativeType(dispatch),
)(CreativesSidebar);