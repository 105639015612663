import React, {Component} from "react";

import CreativesSidebar from "components/RightSidebar/Creatives/CreativesSidebar/CreativesSidebar";
import HTMLSidebar from "./HTMLSidebar/HTMLSidebar";

import styles from "./HTML.module.scss";

export default class HTML extends Component {
	render() {
		return (
			<CreativesSidebar creative={{title: "HTML", icon: "HtmlSM"}}>
				<HTMLSidebar/>
			</CreativesSidebar>
		);
	}
}