import React, {Component} from "react";
import {connect} from "react-redux";
import {Icon} from "factor";

import styles from "./ImportCreatives.module.scss";

import {setActiveCreativeType} from "store/actions";

import {importCreativesList} from "components/consts";

class ImportCreatives extends Component {
	render() {
		const {
			setActiveCreativeType,
		} = this.props;

		return (
			<div className={styles.container}>
				<div className={styles.list}>
					{importCreativesList.map(i => (
						<button
							className={styles.item}
							key={i.title}
							onClick={() => setActiveCreativeType(i.title)}
						>
							<div className={styles.itemIcon}>
								<Icon name={i.icon}/>
							</div>
							<h4 className={styles.itemTitle}>
								{i.title}
							</h4>
						</button>
					))}
				</div>
			</div>
		);
	}
}

export default connect(
	state => ({}),
	dispatch => setActiveCreativeType(dispatch),
)(ImportCreatives);