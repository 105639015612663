import React, {Component} from "react";
import {
	TextField,
	Select,
	Icon,
	Tooltip,
} from "factor";

import Textarea from "components/Textarea/Textarea";
import File from "components/File/File";
import Colorimeter from "components/Colorimeter/Colorimeter";

import styles from "./TickerSidebar.module.scss";

import {testOptions} from "components/consts";

class TickerSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentMessaging: "",
			websiteUrl: "",
			tapAction: "",
			tickerSpeed: null,
		}
	}

	render() {
		const {
			currentMessaging,
			websiteUrl,
			tapAction,
			tickerSpeed,
		} = this.state;

		return (
			<div className={styles.container}>
				<div className={`row ${styles.row}`}>
					<div className="col-6">
						<File
							label={<span className="d-flex align-items-center">
								* Upload Logo
								<Tooltip label="50x50 pixels (optimal), up to 100x50 pixels (max.)">
									<Icon className="_size-14 ml-1" name="Info"/>
								</Tooltip>
							</span>}
							btnTitle="Browse"
						/>
					</div>
				</div>
				<div className={styles.row}>
					<Textarea
						className={styles.textarea}
						label="* Current Messaging"
						placeholder=""
						value={currentMessaging}
						onChange={(v) => this.setState({currentMessaging: v})}
					/>
				</div>
				<div className={`row ${styles.row}`}>
					<div className="col-6">
						<Select
							className={styles.select}
							label="* Ticker Speed"
							showControlLabel
							placeholder="Select"
							value={tickerSpeed}
							options={testOptions}
							onChange={(v) => this.setState({tickerSpeed: v})}
						/>
					</div>
					<div className="col-6">
						<Colorimeter
							label="* Background color"
							color="#f4f4f4"
						/>
					</div>
				</div>
				<div className={`row ${styles.row}`}>
					<div className="col-6">
						<Select
							className={styles.select}
							label="* TapAction"
							showControlLabel
							placeholder="Select"
							value={tapAction}
							options={testOptions}
							onChange={(v) => this.setState({tapAction: v})}
						/>
					</div>
					<div className="col-6">
						<Colorimeter
							label="* Text color"
							color="#000000"
						/>
					</div>
				</div>
				<div className={styles.row}>
					<TextField
						label="* Enter Website URL"
						value={websiteUrl}
						onChange={(v) => this.setState({websiteUrl: v})}
					/>
				</div>
			</div>
		);
	}
}


export default TickerSidebar;