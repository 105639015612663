import React, {Component} from "react";

import CreativesSidebar from "components/RightSidebar/Creatives/CreativesSidebar/CreativesSidebar";
import TickerSidebar from "./TickerSidebar/TickerSidebar";

import styles from "./Ticker.module.scss";

export default class Video extends Component {
	render() {
		return (
			<CreativesSidebar creative={{title: "Ticker", icon: "scrollSM"}}>
				<TickerSidebar/>
			</CreativesSidebar>
		);
	}
}