import React from "react";

import styles from "./File.module.scss";

const File = (props) => {
	const {
		className,
		btnType,
		label,
		btnTitle,
		id,
		onChange = () => {},
	} = props;

	return (
		<div className={`${styles.container} ${className || ""}`}>
			<div className={styles.label}>
				{label}
			</div>
			<label className={`${btnType || "btn-square"} _conflower-blue`} htmlFor={label || id}>
				<input style={{display: "none"}} type="file" id={label || id} onChange={onChange}/>
				{btnTitle || "Browse"}
			</label>
		</div>
	);
};

export default File;