import React, {Component} from "react";
import {Icon} from "factor";

import styles from "./Segment.module.scss";

class Segment extends Component {
	state = {
		search: "",
	}

	render() {
		const {
			list,
			onChange,
		} = this.props;

		return (
			<ul className={styles.list}>
				{list.map(i => {
					let checked = undefined;
					let onClick = undefined;
					if(i.list) {
						checked = i.list.length === i.list.filter(ii => ii.checked === true).length;
						onClick = () => {
							i.list.forEach(ii => ii.checked = !checked);
						}
					} else {
						checked = i.checked;
						onClick = () => i.checked = !checked;
					}

					return <li className={`${styles.item}`} key={i.title}>
						<div className={styles.itemWrapper}>
							<button
								className={`${styles.btn} ${checked ? "_checked" : ""}`}
								key={i.title}
								onClick={() => {
									onClick();
									onChange();
								}}
							>
								{i.title}
								{checked &&
								<i className={styles.checked}>
									<Icon name="Done"/>
								</i>
								}
							</button>
							{i.list &&
								<Segment
									list={i.list}
									onChange={onChange}
								/>
							}
						</div>
					</li>
				})}
			</ul>
		)
	}
}

export default Segment;