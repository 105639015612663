import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Button, ButtonCircle} from "factor";

class HeaderContent extends Component {
	render() {
		return <React.Fragment>
			{window.location.pathname !== "/" &&
				<React.Fragment>
					<Button className={`btn-round _conflower-blue mr-3`}>
						Save as Draft
					</Button>
					< Button className={`btn-round _filled _conflower-blue `}>
						Run
					</Button>
				</React.Fragment>
			}
			<div className="ml-auto" ref={el => this.navMenuWrapper = el}>
				<ButtonCircle
					className="_size-16"
					key="nav-menu-btn"
					iconName="Apps"
					outline={true}
					onClick={this.togglePopup}
				/>
			</div>
		</React.Fragment>;
	}
}

export default withRouter(HeaderContent);