import React, {Component} from "react";
import {connect} from "react-redux";

import styles from "./Main.module.scss";

import {announceMainTransitionEnd} from "store/actions";

class Main extends Component {
	render() {
		const {
			children,
			activeSidebar,
			rightSidebarOpened,
			sidebarTransitionEnd,
			announceMainTransitionEnd,
		} = this.props;

		const classNames = [styles.main];
		if(activeSidebar) {
			classNames.push("_opened");
		}
		if(window.location.pathname === "/advanced" && rightSidebarOpened) {
			classNames.push("_right-sidebar-opened");
		}

		return (
			<div
				ref={el => this.main = el}
				className={classNames.join(" ")}
				onTransitionEnd={({target}) => {
					if(target === this.main) announceMainTransitionEnd(!sidebarTransitionEnd);
				}}
			>
				{children}
			</div>
		);
	}
}

export default connect(
	state => ({
		activeSidebar: state.activeSidebar,
		rightSidebarOpened: state.rightSidebarOpened,
		sidebarTransitionEnd: state.sidebarTransitionEnd,
	}),
	dispatch => announceMainTransitionEnd(dispatch),
)(Main);