import React, {Component} from "react";

import CreativesSidebar from "components/RightSidebar/Creatives/CreativesSidebar/CreativesSidebar";
import VideoSidebar from "./VideoSidebar/VideoSidebar";

import styles from "./Video.module.scss";

export default class Video extends Component {
	render() {
		return (
			<CreativesSidebar creative={{title: "Video", icon: "VideoSM"}}>
				<VideoSidebar/>
			</CreativesSidebar>
		);
	}
}