import React, {Component} from "react";
import {Select, Checkbox, Icon} from "factor";

import AudiencesSidebar from "components/RightSidebar/Audiences/AudiencesSidebar/AudiencesSidebar";

import styles from "./CampaignAudience.module.scss";

import {testOptions} from "components/consts";

class CampaignAudience extends Component {
	state = {
		campaign: null,
		checkboxes: [
			{
				title: "Impression Served",
				checked: true,
			},
			{
				title: "Clicks",
				checked: false,
			},
			{
				title: "Conversion",
				checked: false,
			},
		],
	}

	render() {
		const {
			campaign,
			checkboxes,
		} = this.state;

		return (
			<AudiencesSidebar header={{title: "Campaign Audience", icon: <Icon name="CampaignAudience"/>}}>
				<div className={styles.row}>
					<Select
						label="* Select Campaign"
						showControlLabel
						options={testOptions}
						value={campaign}
						isMulti
						multiPlaceholder={length => `${length} Campaign${length > 1 ? "s" : ""} Selected`}
						allSelectable
						onChange={v => this.setState({campaign: v})}
					/>
				</div>
				<div className={styles.row}>
					<div className="text-label">* Select Audience of</div>
					<ul className={styles.checkboxes}>
						{checkboxes.map(i => (
							<li className={styles.checkbox}>
								<Checkbox
									label={i.title}
									name={i.title}
									checked={i.checked}
									onChange={() => {
										const checkbox = checkboxes.find(ii => ii.title === i.title);
										checkbox.checked = !checkbox.checked;
										this.setState({checkboxes});
									}}
								/>
							</li>
						))}
					</ul>
				</div>
			</AudiencesSidebar>
		)
	}
}

export default CampaignAudience;