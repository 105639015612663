import React, {Component} from "react";
import {DatesPicker, Select, Icon} from "factor";

import AudiencesSidebar from "components/RightSidebar/Audiences/AudiencesSidebar/AudiencesSidebar";
import File from "components/File/File";

import styles from "./GeofarmedAudience.module.scss";

import {testOptions} from "components/consts";

import {ReactComponent as LocationIcon} from "img/geo/location.svg";
import {ReactComponent as PolygonIcon} from "img/geo/polygon.svg";
import {ReactComponent as CongressionalIcon} from "img/geo/congressional.svg";
import {ReactComponent as StateIcon} from "img/geo/state.svg";
import {ReactComponent as CountryIcon} from "img/geo/country.svg";

const geoButtons = [<LocationIcon/>, <PolygonIcon/>, <CongressionalIcon/>, <StateIcon/>, <CountryIcon/>];

class GeofarmedAudience extends Component {
	state = {
		dateRange: {
			start: new Date(1541192400000),
			end: new Date(1541765208969),
		},
		country: null,
		state: null,
	}

	render() {
		const {
			dateRange,
			country,
			state,
		} = this.state;

		return (
			<AudiencesSidebar header={{title: "Geo-farmed Audience", icon: <Icon name="GeofarmedAudience"/>}}>
				<div className={styles.row}>
					<div className="text-label">
						Timeframe
					</div>
					<div className={styles.datespicker}>
						<DatesPicker
							updateDateRange={(start, end) => this.setState({
								dateRange: {
									start: new Date(start),
									end: new Date(end),
								}
							})}
							dateFormat="DD/MM/YYYY"
							dateRange={dateRange}
							datePickerProps={{
								numberOfCalendars: 2,
								maximumDate: new Date(),
								animationAxis: 'Y',
							}}
						/>
					</div>
				</div>
				<div className={styles.row}>
					<Select
						label="* Select Country"
						showControlLabel
						options={testOptions}
						value={country}
						onChange={v => this.setState({country: v})}
					/>
				</div>
				<div className={styles.row}>
					<Select
						label="* Select State"
						showControlLabel
						options={testOptions}
						value={state}
						onChange={v => this.setState({state: v})}
						isMulti
						multiPlaceholder={length => `${length} State${length > 1 ? "s" : ""} Selected`}
						allSelectable
					/>
				</div>
				<div className={`${styles.row} ${styles.geoButtons}`}>
					{geoButtons.map(i => (
						<button className={styles.geoBtn}>
							{i}
						</button>
					))}
				</div>
				<div className={`row justify-content-end ${styles.row}`}>
					<div className="col-6">
						<File btnTitle="Upload CSV" id="Upload CSV"/>
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.map}>
						<iframe
							frameBorder="0"
							style={{border:0}}
							src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB-hR-cY7nDR7uj43o-UW6Q9ECX0CUugOc&q=USA"
							allowFullScreen
						/>
					</div>
				</div>
				<div className={styles.chips}>
					{["Custom Polygon 1", "Nassa County", "NYC Rally"].map(i => (
						<div className={styles.chipsItem}>
							{i}
							<button className={`btn-close ${styles.chipsRemove}`}></button>
						</div>
					))}
				</div>
			</AudiencesSidebar>
		)
	}
}

export default GeofarmedAudience;